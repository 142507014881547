import React, { useEffect, useState } from "react";
import { Table, Spinner, Container } from "react-bootstrap";
import {
  useAccount,
  useConnect,
  useNetwork,
  useSwitchNetwork,
} from "../../hooks/wagmi";
// import { ethers } from "ethers";
import ReactPaginate from "react-paginate";
// import Account from "./Account";
// import { useWalletClient } from "wagmi";

import personL1Bridge from "../../abis/personL1Bridge.json";
import { useConnectWallet } from "@web3-onboard/react";
const optimismSDK = require("@eth-optimism/sdk");

const ethers = require("ethers");

const etherProvider = new ethers.providers.JsonRpcProvider(
  process.env.REACT_APP_L2_RPC_URL
);

const clientToProviderSigner = async (client) => {
  const { account, chain, transport } = client;
  const network = {
    chainId: chain?.id,
    name: chain?.name,
    // ensAddress: chain?.contracts?.ensRegistry?.address,
  };
  // You can use whatever provider that fits your need here.
  const provider = new ethers.providers.Web3Provider(transport, network);
  const signer = await provider.getSigner(account?.address);
  return { provider, signer };
};

const WithdrawAccount = ({ topTabAccount }) => {
  const [transactionLoader, setTransactionLoader] = useState(false);
  const [loader, setLoader] = useState();
  const { address, isConnected } = useAccount();
  const [withdrawDetails, setWithdrawDetails] = useState([]);
  const { chain } = useNetwork();
  const { switchNetwork } = useSwitchNetwork();
  const [personaWithdrawals, setPersonaWithdrawals] = useState([]);
  // const { data: walletClient } = useWalletClient();

  const [{ wallet }] = useConnectWallet();
  const [provider, setProvider] = useState(null);
  useEffect(() => {
    if (wallet?.provider) {
      setProvider(new ethers.providers.Web3Provider(wallet.provider, "any"));
    }
  }, [wallet]);

  const { connectWallet, disconnectWallet } = useConnect();

  useEffect(() => {
    // const fetchWithdrawals = async () => {
    //   try {
    //     setTransactionLoader(false);

    //     const contract = new ethers.Contract(
    //       process.env.REACT_APP_L2_PERSONA_BRIDGE,
    //       personL1Bridge,
    //       etherProvider
    //     );
    //     const filter = contract.filters.PersonaBridgeInitiated(); // Replace with your event name
    //     const events = await contract.queryFilter(filter);

    //     const userWithdrawalEvents = events?.filter((item, index) => {
    //       return item.args.owner?.toLowerCase() === address?.toLowerCase();
    //     });

    //     const blockTimeCalls = [];
    //     const statusCalls = [];

    //     const messenger = await getCrossChain();

    //     for (let index = 0; index < userWithdrawalEvents?.length; index++) {
    //       const element = userWithdrawalEvents[index];
    //       const blockCall = etherProvider.getBlock(element.blockHash);
    //       blockTimeCalls.push(blockCall);
    //       statusCalls.push(messenger.getMessageStatus(element.transactionHash));
    //     }
    //     const blockTime = await Promise.all(blockTimeCalls);
    //     const statusResult = await Promise.all(statusCalls);
    //     const obj = [];

    //     for (let index = 0; index < userWithdrawalEvents?.length; index++) {
    //       const element = userWithdrawalEvents[index];
    //       const depositDetail = {
    //         txHash: element?.transactionHash,
    //         tokenId: Number(element?.args?.tokenId),
    //         timeStamp: formatTimestamp(blockTime[index].timestamp),
    //         status: statusResult[index],
    //         messageStatus: getPersonaStatus(statusResult[index]),
    //       };
    //       obj.push(depositDetail);
    //       setTransactionLoader(true);
    //     }
    //     setPersonaWithdrawals(obj);
    //   } catch (error) {
    //     //console.log("error", error);
    //   }
    // };

    const fetchWithdrawals = async () => {
      try {
        setTransactionLoader(false);

        const contract = new ethers.Contract(
          process.env.REACT_APP_L2_PERSONA_BRIDGE,
          personL1Bridge,
          etherProvider
        );

        const filter = contract.filters.PersonaBridgeInitiated(); // Replace with your event name
        const events = await contract.queryFilter(filter);

        const messenger = await getCrossChain();

        // Filter user events and fetch block timestamps and message statuses concurrently
        const userWithdrawalEvents = events.filter(
          (item) => item.args.owner?.toLowerCase() === address?.toLowerCase()
        );

        const promises = userWithdrawalEvents.map(async (event) => {
          const [block, status] = await Promise.all([
            etherProvider.getBlock(event.blockHash),
            messenger.getMessageStatus(event.transactionHash),
          ]);

          return {
            txHash: event.transactionHash,
            tokenId: Number(event.args.tokenId),
            timeStamp: formatTimestamp(block.timestamp),
            status,
            messageStatus: getPersonaStatus(status),
          };
        });

        const withdrawalDetails = await Promise.all(promises);

        setPersonaWithdrawals(withdrawalDetails);
        setTransactionLoader(true);
      } catch (error) {
        console.error("Error fetching withdrawals:", error);
      }
    };

    if (address && topTabAccount === "persona" && provider) {
      setPersonaWithdrawals([]);
      fetchWithdrawals();
    }
  }, [provider, address, topTabAccount]);

  function formatTimestamp(epochTime) {
    const date = new Date(epochTime * 1000); // Convert from seconds to milliseconds
    const options = {
      day: "numeric",
      month: "short",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    };
    return date.toLocaleString("en-GB", options).replace(",", "");
  }

  const getCrossChain = async () => {
    const l2Url = process.env.REACT_APP_L2_RPC_URL;

    // //console.log("l2Url", l2Url, walletClient);
    // const { signer } = await clientToProviderSigner(walletClient);
    // //console.log("signer", signer);

    // const l1Provider = new ethers.providers.Web3Provider(window.ethereum);
    const l2Provider = new ethers.providers.JsonRpcProvider(l2Url, "any");
    const l1Signer = provider?.getSigner(address); // signer;
    const l2Signer = l2Provider.getSigner(address);
    const zeroAddr = "0x".padEnd(42, "0");
    const l1Contracts = {
      StateCommitmentChain: zeroAddr,
      CanonicalTransactionChain: zeroAddr,
      BondManager: zeroAddr,
      AddressManager: process.env.REACT_APP_LIB_ADDRESSMANAGER,
      L1CrossDomainMessenger:
        process.env.REACT_APP_PROXY_OVM_L1CROSSDOMAINMESSENGER,
      L1StandardBridge: process.env.REACT_APP_PROXY_OVM_L1STANDARDBRIDGE,
      OptimismPortal: process.env.REACT_APP_OPTIMISM_PORTAL_PROXY,
      OptimismPortal2: process.env.REACT_APP_OPTIMISM_PORTAL_PROXY,
      DisputeGameFactory: process.env.REACT_APP_DISPUTE_GAME_FACTORY,

      L2OutputOracle: process.env.REACT_APP_L2_OUTPUTORACLE_PROXY,
    };
    const bridges = {
      Standard: {
        l1Bridge: l1Contracts.L1StandardBridge,
        l2Bridge: process.env.REACT_APP_L2_BRIDGE,
        Adapter: optimismSDK.StandardBridgeAdapter,
      },
      ETH: {
        l1Bridge: l1Contracts.L1StandardBridge,
        l2Bridge: process.env.REACT_APP_L2_BRIDGE,
        Adapter: optimismSDK.ETHBridgeAdapter,
      },
    };
    const crossChainMessenger = new optimismSDK.CrossChainMessenger({
      contracts: {
        l1: l1Contracts,
      },
      bridges: bridges,
      l1ChainId: Number(process.env.REACT_APP_L1_CHAIN_ID),
      l2ChainId: Number(process.env.REACT_APP_L2_CHAIN_ID),
      l1SignerOrProvider: l1Signer,
      l2SignerOrProvider: l2Signer,
      bedrock: true,
    });

    return crossChainMessenger;
  };

  const getWithdraw = async () => {
    const getCrossChainMessenger = await getCrossChain();
    const l2Url = process.env.REACT_APP_L2_RPC_URL;
    const l2Provider = new ethers.providers.JsonRpcProvider(l2Url);

    const data = await getCrossChainMessenger.getWithdrawalsByAddress(address);
    const statusMessageMap = {
      6: "Completed",
      3: "Ready to Prove",
      5: "Claim Withdrawal",
      2: "Waiting for Confirmation",
      4: "In challenge Period",
    };

    // Fetch block timestamps and message statuses concurrently
    const processedData = await Promise.all(
      data.map(async (item) => {
        const [block, messageStatus] = await Promise.all([
          l2Provider.getBlock(item.blockNumber),
          getCrossChainMessenger.getMessageStatus(item.transactionHash),
        ]);

        return {
          ...item,
          messageStatus,
          timestamp: block.timestamp,
          message: statusMessageMap[messageStatus] || null,
        };
      })
    );

    setWithdrawDetails(processedData);

    if (processedData.length > 0) {
      setTransactionLoader(true);
    }
  };

  const getPersonaStatus = (status) => {
    const statusMap = {
      2: "Waiting for Confirmation",
      3: "Ready to Prove",
      4: "In challenge Period",
      5: "Claim Withdrawal",
      6: "Completed",
    };
    return statusMap[status] || "Unknown Status";
  };

  function timeConverter(timestamp) {
    var a = new Date(timestamp * 1000);
    var months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate();
    var hour = a.getHours();
    var min = a.getMinutes();
    var sec = a.getSeconds();
    var time =
      date + " " + month + " " + year + " " + hour + ":" + min + ":" + sec;
    return time;
  }

  const handleProve = async (event, transactionHash) => {
    try {
      const index = event.target.getAttribute("data-value");
      setLoader(index);
      const getCrossChainMessenger = await getCrossChain();
      const response = await getCrossChainMessenger.proveMessage(
        transactionHash
      );
      const logs = await response.wait();
      if (logs.status === 1) {
        getWithdraw();
        setLoader(NaN);
      }
    } catch (error) {
      if (error.code === "ACTION_REJECTED") {
        setLoader(NaN);
      }
    }
  };

  const handleClaim = async (event, transactionHash) => {
    try {
      const index = event.target.getAttribute("data-value");
      setLoader(index);
      const getCrossChainMessenger = await getCrossChain();
      const response = await getCrossChainMessenger.finalizeMessage(
        transactionHash
      );
      const logs = await response.wait();
      if (logs.status === 1) {
        getWithdraw();
        setLoader(NaN);
      }
    } catch (error) {
      // if(error.code === -32603){
      //     //console.log("Already claim");
      // }
      if (error.code === "ACTION_REJECTED") {
        setLoader(NaN);
      }
    }
  };

  useEffect(() => {
    if (isConnected && provider) {
      try {
        if (Number(chain.id) !== Number(process.env.REACT_APP_L1_CHAIN_ID)) {
          const chainIdHex = `0x${parseInt(
            process.env.REACT_APP_L1_CHAIN_ID
          ).toString(16)}`;
          switchNetwork(chainIdHex);
        } else {
          getWithdraw();
        }
      } catch (error) {
        //console.log("error", error);
      }
    }
  }, [provider, chain, address]);

  // =============all Collections pagination start===============
  const [currentItemsCollections, setCurrentItemsCollections] = useState([]);
  const [pageCountCollections, setPageCountCollections] = useState(0);
  const [itemOffsetCollections, setItemOffsetCollections] = useState(0);
  const itemsPerPageCollections = 10;

  const tokenList = [
    {
      type: process.env.REACT_APP_L2_DAI,
      tokenSymbol: "DAI",
      decimalValue: 18,
    },
    {
      type: process.env.REACT_APP_L2_USDT,
      tokenSymbol: "USDT",
      decimalValue: 6,
    },
    {
      type: process.env.REACT_APP_L2_USDC,
      tokenSymbol: "USDC",
      decimalValue: 6,
    },
    {
      type: process.env.REACT_APP_L2_wBTC,
      tokenSymbol: "wBTC",
      decimalValue: 8,
    },
  ];

  function retrieveEthValue(amount, givenType) {
    const weiValue = parseInt(amount._hex, 16);
    const dynamicDecimal =
      tokenList.filter((a) => a.type === givenType)[0]?.decimalValue ===
      undefined
        ? 18
        : tokenList.filter((a) => a.type === givenType)[0]?.decimalValue;
    return weiValue / Number("1".padEnd(dynamicDecimal + 1, 0));
  }

  useEffect(() => {
    if (withdrawDetails) {
      const endOffsetCollections =
        itemOffsetCollections + itemsPerPageCollections;
      setCurrentItemsCollections(
        withdrawDetails.slice(itemOffsetCollections, endOffsetCollections)
      );
      setPageCountCollections(
        Math.ceil(withdrawDetails.length / itemsPerPageCollections)
      );
    } else {
    }
  }, [withdrawDetails, itemOffsetCollections, itemsPerPageCollections]);

  const handlePageClickCollections = (event) => {
    const newOffsetCollections =
      (event.selected * itemsPerPageCollections) % withdrawDetails.length;
    setItemOffsetCollections(newOffsetCollections);
  };
  // =============all Collections pagination end===============
  // //console.log("withdrawDetails", {currentItemsCollections, withdrawDetails});
  return (
    <>
      <div className="account_wrap">
        <Container>
          <div className="account_inner_wrap">
            {/* <Account /> */}
            {topTabAccount === "token" && (
              <>
                <section className="account_withdraw_table">
                  {!transactionLoader ? (
                    <div className="lds-ellipsis">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  ) : withdrawDetails?.length <= 0 ? (
                    <h4 className="text-center text-white">
                      No Transaction Found
                    </h4>
                  ) : (
                    <Table responsive bordered hover variant="dark">
                      <thead>
                        <tr>
                          <th>Time</th>
                          <th>Type</th>
                          <th>Amount</th>
                          <th>Transaction</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentItemsCollections.map((element, index) => {
                          const {
                            timestamp,
                            message,
                            transactionHash,
                            amount,
                            messageStatus,
                            l2Token,
                          } = element;
                          //console.log("message", messageStatus);
                          return (
                            <tr key={index}>
                              <td>{timeConverter(timestamp)}</td>
                              <td>Withdraw</td>
                              <td>
                                {retrieveEthValue(amount, l2Token)}{" "}
                                {tokenList.filter((a) => a.type === l2Token)[0]
                                  ?.tokenSymbol === undefined
                                  ? "ETH"
                                  : tokenList.filter(
                                      (a) => a.type === l2Token
                                    )[0]?.tokenSymbol}
                              </td>
                              <td>
                                {" "}
                                <a
                                  href={`${process.env.REACT_APP_L2_EXPLORER_URL}/tx/${transactionHash}`}
                                  target="_blank"
                                >
                                  {" "}
                                  {`${transactionHash.slice(
                                    0,
                                    8
                                  )}...${transactionHash.slice(-8)}`}
                                </a>
                              </td>
                              <td>
                                {message}{" "}
                                {messageStatus === 3 ? (
                                  index == loader ? (
                                    <button
                                      type="button"
                                      className="btn withdraw_inner_btn"
                                    >
                                      <Spinner animation="border" role="status">
                                        <span className="visually-hidden">
                                          Loading...
                                        </span>
                                      </Spinner>{" "}
                                    </button>
                                  ) : (
                                    <button
                                      type="button"
                                      className="btn withdraw_inner_btn"
                                      data-value={index}
                                      onClick={(event) =>
                                        handleProve(event, transactionHash)
                                      }
                                    >
                                      Prove
                                    </button>
                                  )
                                ) : messageStatus === 5 ? (
                                  index == loader ? (
                                    <button
                                      type="button"
                                      className="btn withdraw_inner_btn"
                                    >
                                      <Spinner animation="border" role="status">
                                        <span className="visually-hidden">
                                          Loading...
                                        </span>
                                      </Spinner>{" "}
                                    </button>
                                  ) : (
                                    <button
                                      type="button"
                                      className="btn withdraw_inner_btn"
                                      data-value={index}
                                      onClick={(event) =>
                                        handleClaim(event, transactionHash)
                                      }
                                    >
                                      Claim
                                    </button>
                                  )
                                ) : (
                                  ""
                                )}{" "}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  )}
                  {withdrawDetails?.length > 10 ? (
                    <div className="pagination_wrap">
                      <ReactPaginate
                        breakLabel="..."
                        nextLabel=" >>"
                        onPageChange={handlePageClickCollections}
                        pageRangeDisplayed={1}
                        marginPagesDisplayed={1}
                        pageCount={pageCountCollections}
                        previousLabel="<< "
                        containerClassName="pagination justify-content-end"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        activeClassName="active"
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </section>
              </>
            )}
            {topTabAccount === "nft" && <h1>Withdraw NFT data</h1>}
            {topTabAccount === "persona" && (
              <>
                <section className="account_withdraw_table">
                  {!transactionLoader ? (
                    <div className="lds-ellipsis">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  ) : personaWithdrawals?.length <= 0 ? (
                    <h4 className="text-center text-white">
                      No Transaction Found
                    </h4>
                  ) : (
                    <Table responsive bordered hover variant="dark">
                      <thead>
                        <tr>
                          <th>Time</th>
                          <th>Type</th>
                          <th>ID</th>
                          <th>Transaction</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {personaWithdrawals?.map((element, index) => {
                          const {
                            timeStamp,
                            status,
                            txHash,
                            tokenId,
                            messageStatus,
                          } = element;
                          //console.log("message", messageStatus);
                          return (
                            <tr key={index}>
                              <td>{timeStamp}</td>
                              <td>Withdraw</td>
                              <td>{tokenId}</td>
                              <td>
                                {" "}
                                <a
                                  href={`${process.env.REACT_APP_L2_EXPLORER_URL}/tx/${txHash}`}
                                  target="_blank"
                                >
                                  {" "}
                                  {`${txHash.slice(0, 8)}...${txHash.slice(
                                    -8
                                  )}`}
                                </a>
                              </td>
                              <td>
                                {messageStatus}{" "}
                                {status === 3 ? (
                                  index == loader ? (
                                    <button
                                      type="button"
                                      className="btn withdraw_inner_btn"
                                    >
                                      <Spinner animation="border" role="status">
                                        <span className="visually-hidden">
                                          Loading...
                                        </span>
                                      </Spinner>{" "}
                                    </button>
                                  ) : (
                                    <button
                                      type="button"
                                      className="btn withdraw_inner_btn"
                                      data-value={index}
                                      onClick={(event) =>
                                        handleProve(event, txHash)
                                      }
                                    >
                                      Prove
                                    </button>
                                  )
                                ) : status === 5 ? (
                                  index == loader ? (
                                    <button
                                      type="button"
                                      className="btn withdraw_inner_btn"
                                    >
                                      <Spinner animation="border" role="status">
                                        <span className="visually-hidden">
                                          Loading...
                                        </span>
                                      </Spinner>{" "}
                                    </button>
                                  ) : (
                                    <button
                                      type="button"
                                      className="btn withdraw_inner_btn"
                                      data-value={index}
                                      onClick={(event) =>
                                        handleClaim(event, txHash)
                                      }
                                    >
                                      Claim
                                    </button>
                                  )
                                ) : (
                                  ""
                                )}{" "}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  )}
                  {withdrawDetails?.length > 10 ? (
                    <div className="pagination_wrap">
                      <ReactPaginate
                        breakLabel="..."
                        nextLabel=" >>"
                        onPageChange={handlePageClickCollections}
                        pageRangeDisplayed={1}
                        marginPagesDisplayed={1}
                        pageCount={pageCountCollections}
                        previousLabel="<< "
                        containerClassName="pagination justify-content-end"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        activeClassName="active"
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </section>
              </>
            )}
          </div>
        </Container>
      </div>
    </>
  );
};

export default WithdrawAccount;
